<template>
  <div class="select-language">
    <div class="select-language__selected" @click="openedSelect">
      <figure class="select-language__selected-arrow">
        <img :src="require(`@/assets/icons/${options[selected].img}`)" alt="" />
      </figure>
      <figure class="select-language__selected-arrow">
        <img src="../assets/icons/arrow-select.svg" alt="" />
      </figure>
    </div>
    <transition name="select">
      <div v-if="isOpen" class="select-language__options">
        <div
          class="select-language__options-item"
          v-for="option in options"
          :key="option"
          @click="selectThisLang(option.id)"
        >
          <figure class="select-language__options-img" v-if="option.id !== selected">
            <img :src="require(`@/assets/icons/${option.img}`)" alt="" />
          </figure>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SelectLanguage",
  data() {
    return {
      options: [
        {
          id: 0,
          language: "uk",
          img: "uk-flag.svg",
          name: "English",
        },
        {
          id: 1,
          language: "usa",
          img: "usa-flag.svg",
          name: "USA",
        },
        
      ],
      selected: 0,
      isOpen: false,
    };
  },
  methods: {
    openedSelect() {
      this.isOpen = !this.isOpen;
    },
    selectThisLang(id) {
      this.selected = id
      this.isOpen = false
    }
  }
};
</script>

<style lang='scss'>
.select-language {
  position: relative;
  cursor: pointer;

  &__selected {
    display: flex;
    align-items: center;

    &-arrow {
      margin-left: rem(5);
      max-width: rem(28);

      display: flex;
      align-items: center;
    }
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    padding: rem(10);
    background-color: $white;

    &-img {
      width: rem(28);
      margin-right: rem(17);

      & img {
        width: 100%;
      }
    }

    &-item:not(:last-child) {
      margin-bottom: rem(8);
    }
  }
}

.select {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>