<template>
  <div class="makes" id="makes">
    <div class="container">
      <div class="makes__info">
        <h2 class="makes__info-title">What makes AnodeVPN unique?</h2>
        <p class="makes__info-description">
          AnodeVPN allows you to pay for the bandwidth speed you want with the
          ease of using PKT. This pay-as-you-go model means you only pay for
          what you use. You can earn PKT by connecting your Internet to the PKT
          Network. Then you can use the PKT you earn to pay for your VPN. It is
          an easy value cycle that benefits you directly.
        </p>
        <div class="makes__info-buttons">
          <a
            href="https://pktpal.com/"
            target="_blank"
            class="makes__info-buttons-link btn"
            >Earn PKT</a
          >
          <a
            href="https://pkt.cash"
            target="_blank"
            class="makes__info-buttons-link btn-light"
            >Learn More</a
          >
        </div>
        <div class="makes__info-klavio" id="makes__info-klavio">
          <div class="klaviyo-form-Y6dyW5"></div>
        </div>
        <!-- <h3 class="makes__info-name">Join Waitlist</h3> -->
        <!-- <form class="makes__info-form" @submit.prevent="sendEmail">
          <input type="text" placeholder="Your name" />
          <input type="text" placeholder="Your email" />
          <button class="btn">Join Waitlist</button>
        </form> -->
      </div>
      <figure class="makes__img">
        <img src="../../assets/img/make-main.svg" alt="" />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "Makes",
  methods: {
    sendEmail() {
      console.log("Hi")
    }
  },
  // mounted() {
  //   let searchSelector = setTimeout(()=>{
  //     if(document.querySelector('.makes button.needsclick')) {
  //       document.querySelector('.makes button.needsclick').addEventListener('click', ()=>{
  //         setTimeout(()=>{
  //           window.scrollBy(0, -200)
  //         }, 100)
  //       }) 
  //     } else {
  //       searchSelector
  //     }
  //   },500)
  //   document.querySelector('.makes button.needsclick').addEventListener('click', ()=>{
  //     window.scrollBy(0, 50)
  //   })
  // }
};
</script>

<style lang='scss'>
.makes {
  background: url(../../assets/img/makes-wave.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;

  padding: rem(140) 0 rem(100);
  @include _768 {
    padding: rem(0) 0;
  }

  & > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include _1200 {
      flex-direction: column;
    }
    @include _768 {
      transform: translateY(-107px);
    }
  }

  &__img {
    width: 46%;
    @include _1200 {
      width: 100%;
      order: -1;
      margin-bottom: rem(30);
    }
    @include _768 {
      margin-bottom: rem(38);
    }
  }

  &__info {
    width: 46%;
    @include _1200 {
      width: 100%;
    }

    &-img {
      margin-bottom: rem(30);
    }

    &-title {
      @extend %h2-title;
      color: $content;
      margin-bottom: rem(10);
    }

    &-description {
      @extend %body;
      color: $secondary-content;
      margin-bottom: rem(42);
    }

    &-buttons {
      margin-bottom: rem(50);
      @include _1200 {
        margin-bottom: rem(50);
      }
      @include _768 {
        margin-bottom: rem(52);
      }

      &-link {
        margin-right: rem(11);
        &.btn {
          padding-left: rem(31);
          padding-right: rem(31);
          @include _768 {
            padding: rem(12) rem(20);
          }
        }

        &.btn-light {
          padding-left: rem(31);
          padding-right: rem(31);
          @include _768 {
            padding: rem(10) rem(20);
          }
        }
      }
    }

    &-name {
      @extend %h3-title;
      color: $content;
      margin-bottom: rem(20);
    }

    
    &-form {
      display: flex;
      @include _768 {
        flex-direction: column;
      }

      & input {
        min-width: rem(150);
        padding: rem(10) rem(15);
        margin-right: rem(10);
        @include _768 {
          width: 100%;
          margin-bottom: rem(10);
        }

        @extend %body;
        border: 1px solid $secondary-grey;
        border-radius: rem(5);
      }

      & button {
        white-space: nowrap;
        @include _768 {
          padding: rem(10) rem(20);
          margin-top: rem(10);
        }
      }
    }
  }
  .klaviyo-form {
    // border-radius: 20px !important;

    & form {
      padding-left: 0 !important;
      padding-right: 0 !important;
      justify-content: flex-start;
      margin-left: -6px !important;
      @include _768 {
        margin-left: 0 !important;
      }
      & > div > div {
        @include _768 {
          flex-direction: column !important;
        }
      }
      & > div > div > div {
        @media (min-width: 1200px) {
          padding-top: rem(10) !important;
        }
        @include _768 {
          padding-left: 0 !important;
          padding-right: 0 !important;
          flex: 1 0 36px !important;
        }
      }
    }

    & p {
      overflow: hidden !important;
      // padding-top: rem(0) !important;
    }

    & p > span {
      font-family: "Poppins-Semi", sans-serif !important;
      font-size: rem(28) !important;
      line-height: rem(39) !important;
      font-weight: 500 !important;
      @include _768 {
        font-size: rem(22) !important;
        line-height: rem(28) !important;
      }
      color: $content !important;
    }

    & input {
      min-width: rem(180) !important;
      @include _1600 {
        min-width: rem(155) !important;
      }
      height: auto !important;
      padding: rem(10) rem(15) !important;
      margin-right: rem(10) !important;
      @include _768 {
        width: 100% !important;
        margin-bottom: rem(10) !important; 
      }

      font-size: rem(18) !important;
      line-height: rem(28) !important;
      font-family: "Roboto", sans-serif !important;
      @include _768 {
        font-size: rem(16) !important;
        line-height: rem(19) !important;
      }
      border: 1px solid $secondary-grey !important;
      border-radius: rem(5) !important;
      &::placeholder {
        line-height: rem(28) !important;
      }
      @include _768 {
        &::placeholder {
          line-height: rem(19) !important;
        }
      }
    }

    & button {
      padding: rem(11) rem(20) !important;
      font-family: "Poppins-Semi", sans-serif !important;
      font-size: rem(18) !important;
      line-height: rem(28) !important;
      @include _768 {
        font-size: rem(16) !important;
        display: block !important;
        width: 100%;
      }
      background-color: $primary !important;
      border-radius: rem(5) !important;
    }
  }
}

</style>