<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__logo">
        <!-- <figure class="footer__logo-logo">
          <img src="../assets/img/footer-logo.svg" alt="" />
        </figure> -->
        <h4 class="footer__logo-text">Join Waitlist Now</h4>
        <a href="#makes" class="footer__logo-download link-scroll">
          <img src="../assets/icons/download.svg" alt="" />
          Download now</a
        >
      </div>
      <div class="footer__resources">
        <h5 class="footer__resources-title">Resources</h5>
        <ul class="footer__resources-list">
          <li class="footer__resources-list-item">
            <a
              href="https://pkt.cash"
              target="_blank"
              class="footer__resources-list-link"
              >PKT</a
            >
          </li>
          <li class="footer__resources-list-item">
            <a
              href="https://pktpal.com"
              target="_blank"
              class="footer__resources-list-link"
              >PKT Pal</a
            >
          </li>
          <li class="footer__resources-list-item">
            <a
              href="https://github.com/cjdelisle/cjdns"
              target="_blank"
              class="footer__resources-list-link"
              >Cjdns</a
            >
          </li>
          <li class="footer__resources-list-item">
            <router-link to="/privacy" class="footer__resources-list-link"
              >Privacy Policy</router-link
            >
          </li>
          <li class="footer__resources-list-item">
            <router-link to="/wallet-help" class="footer__resources-list-link"
              >Anode Wallet Help</router-link
            >
          </li>
        </ul>
      </div>
      <div class="footer__join">
        <h5 class="footer__join-title">Join our community</h5>
        <div class="klaviyo-form-TCPY79"></div>
      </div>
      <!-- <div class="footer__join">
        <h5 class="footer__join-title">Join our community</h5>
        <form class="footer__join-form">
          <input
            type="text"
            placeholder="Your Email"
            class="footer__join-form-input"
          />
          <button class="footer__join-form-button">Subscribe</button>
        </form>
      </div> -->
      <div class="footer__year">© 2020 Anode</div>
      <div class="footer__privacy">
        <ul class="footer__privacy-list">
          <li class="footer__privacy-list-item">
            <a href="#" class="footer__privacy-list-link">Privacy Policy</a>
          </li>
          <li class="footer__privacy-list-item">
            <a href="#" class="footer__privacy-list-link">Terms of use</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
.footer {
  padding: rem(60) 0 rem(65);
  @include _768 {
    padding: rem(40) 0 rem(40);
  }
  background-color: $secondary-content;

  & > .container {
    display: grid;
    grid-template-columns: rem(270) rem(352) 1fr;
    grid-gap: rem(57) rem(20);
    @include _1200 {
      grid-template-columns: 1fr 1fr;
    }
    @include _600 {
      grid-template-columns: 1fr;
      grid-gap: rem(40);
    }
  }

  &__logo {
    &-logo {
      margin-bottom: rem(24);
    }

    &-text {
      margin-bottom: rem(24);

      color: $white;
      @extend %body-xl;
    }

    &-download {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include _480 {
        display: flex;
      }

      padding: rem(10) rem(20);

      @extend %button;
      color: $content;
      background-color: $white;
      border-radius: rem(5);

      & img {
        width: rem(16);
        height: rem(22);

        margin-right: rem(10);
      }
    }
  }

  &__resources {
    display: flex;
    flex-direction: column;

    &-title {
      margin-bottom: rem(10);
      @include _768 {
        margin-bottom: rem(23);
      }

      @extend %body-xl;
      color: $white;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: 50%;
        @include _600 {
          width: auto;
          margin-right: rem(40);
        }
      }

      &-link {
        @extend %body;
        color: $white;
      }
    }
  }

  &__join {
    max-width: rem(430);
    @include _1200 {
      max-width: auto;
      grid-column: 1 / -1;
    }
    &-title {
      margin-bottom: rem(10);

      @extend %body-xl;
      color: $white;
    }

    & p {
      font-family: "Roboto", sans-serif !important;
      margin-bottom: rem(5) !important;
    }

    & p > span {
      margin-bottom: rem(10);

      // color: $white !important;
      // font-size: rem(22) !important;
      // line-height: rem(32) !important;
      // @include _768 {
      //   font-size: rem(18) !important;
      //   line-height: rem(22) !important;
      // }
    }

    & p > br {
      display: none !important;
    }

    & form > div > div:last-child {
      display: flex;

      @include _768 {
        flex-direction: column !important;
      }
    }

    & form {
      padding: 0 !important;
    }

    & input {
      padding: rem(9) rem(20) !important;
      height: auto !important;
      max-width: rem(290) !important;
      margin-right: rem(5) !important;
      @include _600 {
        width: 100% !important;
        max-width: none !important;
        margin-right: rem(0) !important;
        margin-bottom: rem(10) !important;
      }

      font-size: rem(18) !important;
      line-height: rem(28) !important;
      @include _320 {
        font-size: rem(14) !important;
        line-height: rem(25) !important;
      }
      &::placeholder {
        font-size: rem(18) !important;
        line-height: rem(100) !important;
        @include _320 {
          font-size: rem(14) !important;
          line-height: rem(25) !important;
        }
      }
      color: $white !important;
      background-color: transparent !important;
      border: rem(1) solid $white !important;
      border-radius: rem(5) !important;

      &::placeholder {
        color: $secondary-content-2 !important;
        font-size: rem(18) !important;
        line-height: rem(28) !important;
        @include _320 {
          font-size: rem(14) !important;
          line-height: rem(25) !important;
        }
      }
    }

    & button {
      padding: rem(10) rem(20) !important;

      @extend %poppins-semi;
      font-size: rem(18) !important;
      line-height: rem(28) !important;
      @include _768 {
        width: 100%;
        font-size: rem(16) !important;
      }
      color: $content !important;
      background-color: $white !important;
      border-radius: rem(5) !important;
    }
    & .dMHKxR.dMHKxR {
      // flex: 0 0 250px !important;
      padding: 0 !important;
      margin-bottom: rem(10);
      @include _768 {
        flex: 1 0 80px !important;
      }
    }
    & .iHHjaC.iHHjaC {
      padding: 0 !important;
      margin-bottom: rem(10);
    }
  }

  &__year {
    @extend %body-s;
    color: $secondary-content-2;
    @include _600 {
      order: 10;
    }
  }

  &__privacy {
    &-list {
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: 50%;
      }

      &-link {
        @extend %body;
        color: $secondary-content-2;
      }
    }
  }
}
</style>
