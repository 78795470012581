<template>
  <div class="popup">
    <div class="popup__wrap">
      <div class="popup__close" @click="closePopup">
        <img src="../assets/icons/close-popup.svg" alt="">
      </div>
      <div class="klaviyo-form-TXmfsc"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  methods: {
    sendInfo() {
      console.log('Send message')
    },
    closePopup() {
      this.$store.dispatch('actStatePopup', false)
    }
  }
};
</script>

<style lang='scss'>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: rem(30) rem(20);

  background-color: rgba(0, 0, 0, 0.2);
  z-index: 200;

  &__wrap {
    display: flex;
    flex-direction: column;
    position: relative;

    padding: rem(50) rem(107) rem(100);
    @include _880 {
      padding: rem(20);
    }
    width: rem(660);
    max-width: 100%;
    max-height: 90vh;

    background-color: $white;
    border-radius: rem(5);
  }

  &__close {
    position: absolute;
    top: rem(20);
    right: rem(20);
    @include _768 {
      top: rem(15);
      right: rem(15);
    }

    cursor: pointer;

    & img {
      width: rem(19);
      height: rem(19);
      @include _768 {
        width: rem(15);
        height: rem(15);
      }
    }
  }

  & .klaviyo-form img {
    width: rem(68) !important;
    height: rem(68) !important;
    margin-bottom: rem(35);
  }

  & p {
    overflow: hidden !important;
  }

  & p > span  {
    margin-top: rem(15) !important;

    color: $content;
    font-family: "Poppins-Semi", sans-serif !important;
    text-align: center;
  }

  // &__form {
  //   display: flex;
  //   flex-direction: column;
  // }

  & input {
    height: auto !important;
    padding: rem(10) rem(15) !important;
    // margin-bottom: rem(10);
    font-family: "Roboto", sans-serif !important;

    font-size: rem(18) !important;
    line-height: rem(28) !important;
    @include _320 {
      font-size: rem(14) !important;
      line-height: rem(25) !important;
    }
    &::placeholder {
      line-height: rem(28) !important;
      color: $secondary-grey !important;
      opacity: 1 !important;
      @include _320 {
        line-height: rem(25) !important;
      }
    }
    border: rem(1) solid $secondary-grey !important;
    border-radius: rem(5) !important;
  }

  & button {
    padding: rem(10) rem(15);
    margin-top: rem(10);

    font-family: "Poppins-Semi", sans-serif !important;
    font-size: rem(18) !important;
    line-height: rem(28) !important;
    @include _768 {
      font-size: rem(16) !important;
    }
    border-radius: rem(5);
    background-color: $primary;
    color: $white;
  }

  & .form-row:last-child > span {
    font-size: 10px !important;
  }
}
</style>