<template>
  <div class="best">
    <div class="container">
      <div class="best__text">
        <h2 class="best__title">Why we are the best</h2>
        <h3 class="best__subtitle">
          AnodeVPN isn’t just an offer, it’s a marketplace
        </h3>
      </div>
      <a href="#makes" class="btn-blue best__link link-scroll">Join Waitlist</a>
      <div class="table">
        <div class="table__header">
          <div class="table__header-item">
            <span class="table__header-item-text">Free</span>
          </div>
          <div class="table__header-item">
            <span class="table__header-item-text">User-rated</span>
          </div>
          <div class="table__header-item">
            <span class="table__header-item-text">Ease of payments</span>
          </div>
          <div class="table__header-item">
            <span class="table__header-item-text">Not Vendor-locked</span>
          </div>
          <div class="table__header-item">
            <span class="table__header-item-text">Pay with PKT</span>
          </div>
          <div class="table__header-item">
            <span class="table__header-item-text">Decentralized</span>
          </div>
        </div>
        <swiper :slides-per-view="1" :pagination="{ clickable: true }">
          <swiper-slide>
            <div class="table__column">
              <div class="table__column-item table__column-item--blue">
                <span class="table__column-item--blue-text">Anode VPN</span>
              </div>
              <div class="table__column-item table__column-item--blue">
                <img src="../../assets/icons/check-white.svg" alt="" />
              </div>
              <div class="table__column-item table__column-item--blue">
                <img src="../../assets/icons/check-white.svg" alt="" />
              </div>
              <div class="table__column-item table__column-item--blue">
                <img src="../../assets/icons/check-white.svg" alt="" />
              </div>
              <div class="table__column-item table__column-item--blue">
                <img src="../../assets/icons/check-white.svg" alt="" />
              </div>
              <div class="table__column-item table__column-item--blue">
                <img src="../../assets/icons/check-white.svg" alt="" />
              </div>
              <div class="table__column-item table__column-item--blue">
                <img src="../../assets/icons/check-white.svg" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="table__column">
              <div class="table__column-item">
                <span class="table__column-item-text">Taychon</span>
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/check.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/check.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/check.svg" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="table__column">
              <div class="table__column-item">
                <span class="table__column-item-text">NordVPN</span>
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/check.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="table__column">
              <div class="table__column-item">
                <span class="table__column-item-text">CyberGhost</span>
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/check.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="table__column">
              <div class="table__column-item">
                <span class="table__column-item-text">ExpressVPN</span>
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/check.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
              <div class="table__column-item">
                <img src="../../assets/icons/error.svg" alt="" />
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="table__content">
          <div class="table__column">
            <div class="table__column-item table__column-item--blue">
              <span class="table__column-item--blue-text">Anode VPN</span>
            </div>
            <div class="table__column-item table__column-item--blue">
              <img src="../../assets/icons/check-white.svg" alt="" />
            </div>
            <div class="table__column-item table__column-item--blue">
              <img src="../../assets/icons/check-white.svg" alt="" />
            </div>
            <div class="table__column-item table__column-item--blue">
              <img src="../../assets/icons/check-white.svg" alt="" />
            </div>
            <div class="table__column-item table__column-item--blue">
              <img src="../../assets/icons/check-white.svg" alt="" />
            </div>
            <div class="table__column-item table__column-item--blue">
              <img src="../../assets/icons/check-white.svg" alt="" />
            </div>
            <div class="table__column-item table__column-item--blue">
              <img src="../../assets/icons/check-white.svg" alt="" />
            </div>
          </div>
          <div class="table__column">
            <div class="table__column-item">Taychon</div>
            <div class="table__column-item">
              <img src="../../assets/icons/check.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/check.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/check.svg" alt="" />
            </div>
          </div>
          <div class="table__column">
            <div class="table__column-item">NordVPN</div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/check.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
          </div>
          <div class="table__column">
            <div class="table__column-item">ExpressVPN</div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/check.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
          </div>
          <div class="table__column">
            <div class="table__column-item">CyberGhost</div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/check.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
            <div class="table__column-item">
              <img src="../../assets/icons/error.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper.scss";
// import "swiper/components/pagination/pagination.scss";

SwiperCore.use(Pagination);

export default {
  name: "Best",
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    countSlide() {
      return window.innerWidth >= 730 ? 5 : 1;
    },
  },
};
</script>

<style lang='scss'>
.best {
  padding: rem(100) 0;
  @include _768 {
    padding: rem(60) 0 rem(90);
  }

  & > .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  &__title {
    @extend %h2-title;
    color: $content;
    margin-bottom: rem(10);
  }

  &__subtitle {
    @extend %h3-title;
    color: $secondary-content;
    margin-bottom: rem(30);
  }

  &__link {
    display: inline-block;
    margin-bottom: rem(40);
    padding: rem(10) rem(30) !important;
    @include _1200 {
      margin: rem(50) auto 0;
      order: 10;
    }

    @include _768 {
      margin-top: rem(80);
      margin-bottom: 0;
    }
  }

  & .table {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative !important;

    padding: rem(40) rem(35);
    @include _1200 {
      padding: rem(20);
    }

    background-color: $white;
    box-shadow: 0px 5px 10px rgba(3, 44, 126, 0.18);
    border-radius: rem(5);

    &__content {
      display: flex;
      @include _730 {
        display: none;
      }
    }
    & .swiper-wrapper {
      position: static !important;
    }

    & .swiper-container {
      width: 1000px;
      position: static !important;
      display: none;
      @include _730 {
        display: block;
      }

      & .swiper-pagination {
        width: 100%;
        position: absolute;
        left: 0 !important;
        bottom: rem(-89) !important;
        @include _768 {
          bottom: rem(-47) !important;
        }
        display: flex;
        justify-content: space-between;

        & .swiper-pagination-bullet {
          width: rem(56);
          height: rem(6);
          border-radius: rem(6);
          background-color: $secondary-content !important;
          opacity: 0.2;
          display: block;
          margin: 0 rem(20) 0 0 !important;
          &:last-child {
            margin: 0 !important;
          }

          &-active {
            opacity: 1;
          }
        }
      }
    }

    & .swiper-wrapper {
      display: flex;
      text-align: center !important;
    }

    &__column-item,
    &__header-item {
      height: rem(70);
      @include _768 {
        height: rem(53);
      }
    }

    &__column {
      min-width: rem(180);
      @include _1350 {
        min-width: rem(150);
      }
      @include _1200 {
        min-width: rem(110);
      }
      @include _880 {
        min-width: rem(80);
      }
      @include _768 {
        min-width: rem(100);
      }
      @include _600 {
        min-width: rem(120);
      }
      @include _360 {
        min-width: rem(100);
      }

      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-direction: column;

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;

        &-text {
          @extend %body;
          color: $content;
        }

        &:nth-child(2n) {
          background-color: $secondary-bg;
        }

        &:first-child {
          height: rem(70);
        }

        &--blue {
          background-color: $primary !important;
          &-text {
            color: $white !important;
          }
        }
      }
    }

    &__header {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: stretch;
      flex-direction: column;
      min-width: rem(160);
      @include _600 {
        min-width: rem(180);
      }
      @include _480 {
        min-width: rem(150);
      }
      @include _360 {
        min-width: rem(120);
      }

      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding: 0 rem(30);
        @include _1200 {
          padding: 0 rem(10);
        }

        &-text {
          @extend %body-l;
          color: $content;
        }

        &:nth-child(2n-1) {
          background-color: $secondary-bg;
        }
      }
    }
  }
}
</style>