<template>
  <div class="meets">
    <div class="container">
      <h2 class="meets__title">PKT meets cjdns</h2>
      <div class="meets__wrap">
        <div class="meets__card">
          <figure class="meets__card-title">
            <img src="../../assets/img/meets-card-1.svg" alt="" />
          </figure>
          <p class="meets__card-description">
            PKT is a currency that is minted by connecting an Internet
            connection to the PKT Network. PKT can then be used to pay for
            faster VPN connection speed on the AnodeVPN marketplace.
          </p>
          <a
            href="https://pkt.cash"
            target="_blank"
            class="meets__card-link btn-blue"
            >Visit PKT</a
          >
          <a
            href="https://github.com/pkt-cash/pktd/tree/develop"
            target="_blank"
            class="meets__card-sourse"
            >See source code</a
          >
        </div>
        <div class="meets__card">
          <figure class="meets__card-title">
            <img src="../../assets/img/meets-card-2.svg" alt="" />
          </figure>
          <p class="meets__card-description">
            AnodeVPN uses cjdns to encrypt and route communications between you
            and whichever VPN you choose. The powerful compact source routing
            used in cjdns will allow future versions of AnodeVPN to build
            “circuits” that connect bandwidth leases on multiple VPNs.
          </p>
          <a
            href="https://github.com/cjdelisle/cjdns"
            target="_blank"
            class="meets__card-link btn-blue"
            >Explore cjdns</a
          >
          <a
            href=" https://github.com/cjdelisle/cjdns"
            target="_blank"
            class="meets__card-sourse"
            >See source code</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Meets",
};
</script>

<style lang='scss'>
.meets {
  padding: rem(80) 0 rem(100);
  @include _768 {
    padding: rem(43) 0 rem(60);
  }

  &__title {
    margin-bottom: rem(50);
    @include _768 {
      margin-bottom: rem(20);
    }

    @extend %h2-title;
    color: $content;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    @include _768 {
      flex-direction: column;
    }
  }

  &__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    width: 46%;
    @include _768 {
      width: 100%;
      margin: rem(10) auto;
      max-width: rem(500);
    }
    padding: rem(40);
    @include _768 {
      padding: rem(20);
    }

    border-radius: rem(5);
    text-align: center;
    box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.97),
      8px 8px 20px rgba(3, 44, 126, 0.15);

    &-title {
      margin-bottom: rem(43);
      height: rem(60);
      @include _768 {
        max-width: rem(100);
        margin-bottom: rem(1);
      }
    }

    &-description {
      @extend %body;
      color: $secondary-content;
      margin-bottom: rem(60);
      @include _768 {
        margin-bottom: rem(30);
      }
    }

    &-link {
      margin-top: auto;
      padding: rem(10) rem(44) !important;
    }

    &-sourse {
      display: block;

      margin-top: rem(20);

      @extend %button;
      color: $primary;
      text-decoration: underline;
    }
  }
}
</style>