<template>
  <div>
    <Header />
    <section>
      <div class="help">
        <div class="container">
          <h1 class="help__header">Anode iOS Wallet</h1>
          <p class="help__description">
            The Anode Wallet is a free iOS app that lets you store and spend PKT
            Cash. It has been developed with love by the Anode team.
          </p>
          <p class="help__description">
            The Anode Wallet is designed so that you can send and receive PKT,
            organize your PKT addresses, view your PKT balance, and give
            nicknames to PKT addresses.
          </p>
          <p class="help__description">
            Let's see how to use the Anode Wallet.
          </p>
          <h2 class="help__title">Set Up a Wallet</h2>
          <p class="help__description">
            When you run the Anode Wallet for the first time, you will be asked
            to set up a wallet. You can either create a new wallet or recover an
            existing wallet.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/first.jpg"
              alt="screenshot: first screen"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <h3 class="help__subtitle">Creating a New Wallet</h3>

          <p class="help__description">
            When you set up a new wallet, you will be asked to provide a
            password. The password is used to generate a unique seed phrase that
            represents your wallet, and also is used to encrypt your data on
            your phone.
          </p>

          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/createpassword.jpg"
              alt="screenshot: create new password"
              width="147"
              height="320"
              class="help__screenshot"
            />

            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/seedphraseinstructions.jpg"
              alt="screenshot: seed phrase instructions"
              width="147"
              height="320"
              class="help__screenshot"
            />

            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/walletinstructions.jpg"
              alt="screenshot: wallet instructions"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            The app will generate a Seed Phrase for you. This is a list of 15
            words that you can use to recover your wallet if you lose your
            phone. You should write down the Seed Phrase and keep it in a safe
            place. If you lose your phone, you can use the Seed Phrase to
            recover your wallet on a new phone.
          </p>

          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/seedwords.jpg"
              alt="screenshot: seed words"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/confirmseedwords.jpg"
              alt="screenshot: confirm seed words"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            Once the wallet is set up, you will be asked to enter a 4-digit
            login PIN. The PIN is used to unlock the app. You will be brought to
            the main screen, where you will have a list of PKT addresses. When
            you first set up the wallet, you have one address that doesn't hold
            any PKT yet. You can get PKT by purchasing some, by mining, or by
            asking someone to send you PKT.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/pinlogin.jpg"
              alt="screenshot: pin login"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/newwallet.jpg"
              alt="screenshot: new wallet"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>

          <h3 class="help__subtitle">Recover an Existing Wallet</h3>
          <p class="help__description">
            If you already have a wallet, you can set up the Anode App with your
            existing wallet. You will be asked to provide your Seed Phrase and
            the password you used to set up your wallet before.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/loadwallet/load-wallet-info.jpg"
              alt="screenshot: load wallet info"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/loadwallet/load-wallet-no-seed.jpg"
              alt="screenshot: load wallet no seed"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/loadwallet/load-wallet-seed.jpg"
              alt="screenshot: load wallet seed"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            If the Seed Phrase and password are correct, you will be asked to
            provide another password, which will be used to encrypt your wallet
            data on your phone.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/loadwallet/load-wallet-password.jpg"
              alt="screenshot: load wallet password"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            If the Seed Phrase and password are correct, you will be asked to
            provide a new 4-digit login PIN. The PIN is used to unlock the app.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/loadwallet/load-wallet-create-pin.jpg"
              alt="screenshot: load wallet create pin"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            Once the wallet is set up, you will be brought to the main screen to
            see a list of your PKT addresses. You may have more PKT addresses
            than those listed. You can recover more addresses by clicking the
            "Add Address" button.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/loadwallet/load-wallet-intermission.jpg"
              alt="screenshot: load wallet intermission"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/newwallet.jpg"
              alt="screenshot: new wallet"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>

          <h2 class="help__title">Addresses and Contacts</h2>
          <p class="help__description">
            The Anode Wallet lets you organize your PKT addresses. You can have
            multiple addresses in your own wallet and you can also have contacts
            that have PKT addresses. Both contacts and your wallet addresses can
            be given nicknames so that you can easily recognize them.
          </p>
          <h3 class="help__subtitle">Creating Addresses</h3>
          <p class="help__description">
            You can create new addresses by clicking the "Add Address" button.
            You can give the address a nickname so that you can easily recognize
            it.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/createaddress/create-address-before.jpg"
              alt="screenshot: create address before"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/createaddress/create-address.jpg"
              alt="screenshot: create address"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/createaddress/create-address-name.jpg"
              alt="screenshot: create address name"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/createaddress/create-address-after.jpg"
              alt="screenshot: create address after"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            Having multiple addresses may be convenient for organizing how PKT
            is used or how much is spent. For example, you can have one address
            for mining, one address for spending, and one address for saving.
          </p>
          <p class="help__description">
            Because addresses are verified on the Blockchain, once an address is
            created, there is no way to remove it.
          </p>
          <h3 class="help__subtitle">Creating Contacts</h3>
          <p class="help__description">
            You can also create contacts to better recognize PKT addresses you
            send PKT to or receive PKT from. You can create a contact on the
            Contacts screen by clicking the "Add Contact" button. You can give
            the contact a name and a PKT address.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/contacts/contacts-empty.jpg"
              alt="screenshot: contacts empty"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/contacts/contacts-add-address.jpg"
              alt="screenshot: contacts add address"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/contacts/qr-camera.jpg"
              alt="screenshot: qr camera"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/contacts/qr-scanned.jpg"
              alt="screenshot: qr scanned"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/contacts/contacts-with-address.jpg"
              alt="screenshot: contacts with address"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            Later when you are choosing who to send PKT to, you can choose the
            contact from a list instead of typing in the PKT address.
          </p>
          <p class="help__description">
            Transactions sent to or received from a contact will include the
            contact's nickname in the transaction details.
          </p>
          <p class="help__description">
            You can edit or delete a contact by clicking on a contact. Deleting
            the contact will not delete the PKT address, only the nickname you
            gave to the contact.
          </p>

          <h2 class="help__title">Receiving and Sending PKT</h2>
          <p class="help__description">
            If you touch an address in your main wallet screen, you will be
            brought to the address screen. This screen has options to receive
            and send PKT, and to view the transaction history related to that
            address.
          </p>
          <h3 class="help__subtitle">Receiving PKT</h3>
          <p class="help__description">
            A PKT address is a string of characters that starts with "pkt" and
            it behaves like a bank account number. People can deposit PKT to
            your address and you can send money from that address if it holds
            enough PKT.
          </p>
          <p class="help__description">
            You can receive PKT by clicking the "Receive" button next to the
            address you want to receive PKT to. You will be shown a QR code and
            a text PKT address. You can share either one to someone who wishes
            to pay you in PKT.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/newwallet.jpg"
              alt="screenshot: new wallet"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/receiving/address-no-transactions.jpg"
              alt="screenshot: address no transactions"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            You can copy the address to the clipboard by clicking the "Copy" or
            you can send the address to someone by clicking the "Share" button.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/receiving/address-qr-code.jpg"
              alt="screenshot: address qr code"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/receiving/address-qr-share.jpg"
              alt="screenshot: address qr share"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>

          <h3 class="help__subtitle">Sending PKT</h3>
          <p class="help__description">
            You can send PKT by clicking the "Send" button next to the address
            you want to send PKT from. You will be brought to the Send screen.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/transactions/wallet-with-addresses.jpg"
              alt="screenshot: wallet with addresses"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/send/send-no-recipient.jpg"
              alt="screenshot: send no recipient"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            The send screen has a field for the PKT address you want to send
            from, which is already filled in with the address you are sending
            from. You can change the address if you want to send PKT to a
            different address.
          </p>
          <p class="help__description">
            On the send screen, you can select who to send PKT to. You can do
            this by typing or pasting an address into a field, by scanning a QR
            code, or by selecting an address from your contacts.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/send/send-no-recipient.jpg"
              alt="screenshot: send no recipient"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/send/send-choose-recipient.jpg"
              alt="screenshot: send choose recipient"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/send/send-no-amount.jpg"
              alt="screenshot: send no amount"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/send/send-with-amount.jpg"
              alt="screenshot: send with amount"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            Once you have selected an address to send to, you can enter the
            amount of PKT you want to send. You can also enter a personal note
            to send with the PKT.
          </p>
          <p class="help__description">
            For security reasons, you must enter your PIN before you can send
            PKT. Clicking "Confirm" will post the transaction to the Internet,
            where it may take a few moments for the transaction to be processed.
            You will receive a transaction ID that you can use to track the
            status of the transaction or share with the person you sent PKT to.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/send/preview-send-no-pin.jpg"
              alt="screenshot: preview send no pin"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/send/send-confirmed.jpg"
              alt="screenshot: send confirmed"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            Please note that it may take a few minutes for the PKT to show up in
            the recipient's address. There is no way to cancel a transaction
            after it has been sent.
          </p>

          <h3 class="help__subtitle">Viewing Transactions</h3>
          <p class="help__description">
            After a transaction has been verified on the Blockchain, it will
            show up in the transaction history for an address. You can view the
            details of a transaction by clicking on it. The transaction details
            include the sender and receiver addresses, the amount of PKT sent,
            and the transaction ID.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/createaddress/create-address-before.jpg"
              alt="screenshot: create address before"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/transactions/address-details-with-balance.jpg"
              alt="screenshot: address details with balance"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/transactions/transaction-details.jpg"
              alt="screenshot: transaction details"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/transactions/transaction-block-explorer.jpg"
              alt="screenshot: transaction-block-explorer"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            Note that it may take a moment to download the transaction details
            from the Internet. If you have not used the app in a while, it may
            be necessary to wait for the app to download the latest Blockchain
            data before recent transactions become visible in the app.
          </p>

          <h2 class="help__title">App Security</h2>
          <p class="help__description">
            The Anode App has several security features to protect your PKT. The
            PIN is used to protect your wallet. The password is used to encrypt
            your seed phrase and wallet data. The seed phrase is used to recover
            your wallet if you lose your phone or delete the app.
          </p>
          <p class="help__description">
            You can view your seed phrase, change your PIN, or change your
            password at any time from the Settings screen.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/settings/settings.jpg"
              alt="screenshot: settings"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <h3 class="help__subtitle">Changing Your PIN</h3>
          <p class="help__description">
            Your PIN is used to protect your wallet. You can change your PIN by
            clicking the "Change PIN" button on the Settings screen.
          </p>
          <p class="help__description">
            You will be asked to enter your current PIN, and then you will be
            asked to enter a new PIN twice.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/settings/settings.jpg"
              alt="screenshot: settings"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/pinlogin.jpg"
              alt="screenshot: pin login"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/settings/change-pin.jpg"
              alt="screenshot: change pin"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <h3 class="help__subtitle">Changing Your Password</h3>
          <p class="help__description">
            Your password is used to encrypt your seed phrase and wallet data.
            If you forget your password, you will not be able to recover your
            wallet. If you change your password, your seed phrase will change as
            a result of the updated encryption.
          </p>
          <p class="help__description">
            To change your password, click the "Change Password" button on the
            Settings screen.
          </p>
          <p class="help__description">
            You will be asked to enter your current password, and then you will
            be asked to enter a new password twice.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/settings/settings.jpg"
              alt="screenshot: settings"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/settings/change-password.jpg"
              alt="screenshot: change password"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/settings/change-password-new-seed.jpg"
              alt="screenshot: change password new seed"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            Note that changing your password will change your seed phrase. You
            will need to write down your new seed phrase and keep it in a safe
            place.
          </p>
          <h3 class="help__subtitle">Viewing Your Seed Phrase</h3>
          <p class="help__description">
            The Seed Phrase is a list of 15 words that can be used to recover a
            PKT wallet if you lose your phone or delete the app. It is important
            to keep your seed phrase in a safe place. If you lose your seed
            phrase, you will lose access to your PKT.
          </p>
          <p class="help__description">
            You can view your seed phrase by clicking the "View Seed Phrase"
            option in the Settings. For security reasons, you will be asked to
            enter your PIN before you can view your Seed Phrase.
          </p>
          <p class="help__description">
            <img
              src="../assets/img/screenshots/anode-wallet/settings/settings.jpg"
              alt="screenshot: settings"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/createwallet/pinlogin.jpg"
              alt="screenshot: pin login"
              width="147"
              height="320"
              class="help__screenshot"
            />
            <img
              src="../assets/img/screenshots/anode-wallet/settings/view-seed-phrase.jpg"
              alt="screenshot: view seed phrase"
              width="147"
              height="320"
              class="help__screenshot"
            />
          </p>
          <p class="help__description">
            Your Seed Phrase is encrypted with your password. It changes each
            time you change your password. If you forget your password, you will
            not be able to recover your wallet.
          </p>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer";
import Header from "../components/Header";

export default {
  name: "AnodeWalletHelp",
  components: {
    Header,
    Footer,
  },
  methods: {},
};
</script>

<style lang="scss">
.help {
  padding: rem(95) 0 rem(100);
  @include _768 {
    padding: rem(52) 0 rem(48);
  }

  &__header {
    @extend %h2-title;
    color: $content;
    @include _768 {
      margin-bottom: rem(5);
    }
  }

  &__title {
    @extend %h3-title;
    color: $content;
    @include _768 {
      margin-bottom: rem(5);
    }
  }

  &__subtitle {
    @extend %h3-title;
    color: $secondary-content;
    margin-bottom: rem(20);
    @include _768 {
      margin-bottom: rem(18);
    }
  }

  &__description {
    @extend %body;
    color: $content;
    margin-bottom: rem(47);
    @include _768 {
      margin-bottom: rem(23);
    }
  }

  &__screenshot {
    @extend %body;
    color: $content;
    margin-right: rem(47);
    @include _768 {
      margin-right: rem(23);
    }
  }

  &__list-item {
    @extend %body;
    color: $content;
    margin-bottom: rem(47);
    @include _768 {
      margin-bottom: rem(23);
    }
  }
}
</style>
