<template>
  <div class="invite">
    <div class="container">
      <h2 class="invite__title">Is privacy for all your mission as well?</h2>
      <a class="btn-blue btn lightbox-203031271661039 invite__link"
        >Join our team</a
      >
    </div>
    <figure class="invite__close" v-if="showForm" @click="hideJotForm">
      <img src="../../assets/icons/close-popup.svg" alt="" />
    </figure>
  </div>
</template>

<script>
export default {
  name: "Privacy",
  data() {
    return {
      showForm: false,
    };
  },
  methods: {
    showJotForm() {
      this.showForm = true;
    },
    hideJotForm() {
      this.showForm = false;
    },
  },
};
</script>

<style lang='scss'>
</style>