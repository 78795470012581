<template>
  <div class="section-card" id="section-card">
    <div class="container">
      <div class="section-card__item">
        <div class="section-card__item-info">
          <figure class="section-card__item-info-img">
            <img src="../../assets/icons/success.svg" alt="" />
          </figure>
          <h3 class="section-card__item-info-title">VPN Simplified</h3>
          <p class="section-card__item-info-description">
            The user-rated AnodeVPN marketplace is easy to use. Download the app
            and get started for FREE. Need a faster connection? Only pay for the
            speed you need, when you need it. VPN has never been this simple.
          </p>
        </div>
        <figure class="section-card__item-img section-card__item-img--one">
          <img src="../../assets/img/card-1.png" alt="" />
        </figure>
      </div>
      <div class="section-card__item">
        <figure class="section-card__item-img section-card__item-img--two">
          <img src="../../assets/img/card-2.png" alt="" />
        </figure>
        <div class="section-card__item-info">
          <figure class="section-card__item-info-img">
            <img src="../../assets/icons/surface.svg" alt="" />
          </figure>
          <h3 class="section-card__item-info-title">Privacy is for everyone</h3>
          <p class="section-card__item-info-description">
            You should protect yourself and your family from the insecurities of
            the Internet. Want to keep your data secure? AnodeVPN makes sure
            having privacy is free and easy.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionCard",
};
</script>

<style lang='scss'>
.section-card {
  margin-top: rem(-62);
  padding: 0 0 rem(70);
  @include _1200 {
    padding: rem(50) 0;
  }
  @include _768 {
    padding: rem(130) 0 rem(207);
    overflow: hidden;
  }

  & > .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: rem(40);
    @include _768 {
      grid-gap: rem(108);
    }
  }

  &__item {
    background-color: $secondary-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include _600 {
      flex-direction: column;
    }

    padding: rem(40) rem(80);
    @include _1200 {
      padding: rem(30);
    }
    @include _600 {
      padding: rem(20);
    }

    box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.97),
      8px 8px 20px rgba(3, 44, 126, 0.15);
    border-radius: rem(5);

    &-info {
      width: 46%;
      @include _600 {
        width: 100%;
        margin-top: rem(-20);
      }

      &-img {
        margin-bottom: rem(40);
        @include _768 {
          margin-bottom: rem(14);
        }
      }

      &-title {
        @extend %h3-title;
        color: $content;
        margin-bottom: rem(10);
      }

      &-description {
        @extend %body;
        color: $secondary-content;
      }
    }

    &-img {
      width: 46%;
      &--one img {
        transform: scale(1.25) translateY(7%);
      }

      &--two img {
        transform: scale(1.25) translateY(7%);
      }

      @include _600 {
        width: 100%;
        order: -1;
        &--one img {
          transform: scale(1.3) translate(25px, -20px);
        }

        &--two img {
          transform: scale(1.3) translate(-25px, -20px);
        }
      }

      @include _480 {
        width: 100%;
        order: -1;
        &--one img {
          transform: scale(1.5) translate(15px, -20px);
        }

        &--two img {
          transform: scale(1.5) translate(-15px, -20px);
        }
      }
    }
  }
}
</style>