<template>
  <div class="last-section">
    <div class="container">
      <h2 class="last-section__title">
        Anode <img src="../../assets/icons/heart.svg" alt="" /> Open Source
        Software
      </h2>
      <h3 class="last-section__subtitle">
        We don’t just use open-source software, we live it. The AnodeVPN stack
        is available for you to copy, remix and optimize.
      </h3>
      <span class="last-section__description">
        If you want to improve on what we do, check out Github to see how we do
        it.
      </span>
      <div class="last-section__buttons">
        <a
          href="https://github.com/anode-co/AnodeVPN-android"
          target="_blank"
          class="btn-blue last-section__buttons-link"
        >
          <img src="../../assets/icons/android.svg" alt="" />Android</a
        >
        <a
          href="https://github.com/anode-co/anodevpn-server"
          target="_blank"
          class="btn-blue last-section__buttons-link"
        >
          <img src="../../assets/icons/vpn-symbol.svg" alt="" />VPN</a
        >
        <a
          href="https://github.com/anode-co/vpn-api-server"
          target="_blank"
          class="btn-blue last-section__buttons-link"
        >
          <img src="../../assets/icons/server.svg" alt="" />Server</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LastSection",
};
</script>

<style lang='scss'>
.last-section {
  padding: rem(100) 0 rem(155);
  @include _1200 {
    padding: rem(60) 0 rem(110);
  }
  @include _768 {
    padding: rem(60) 0 rem(89);
  }
  text-align: center;

  &__title {
    @extend %h2-title;
    color: $content;
    max-width: 65%;
    @include _320 {
      max-width: 80%;
    }
    margin: 0 auto;
    margin-bottom: rem(20);
    @include _768 {
      margin-bottom: rem(10);
    }
    & img {
      @include _768 {
        height: rem(26);
      }
    }
  }

  &__subtitle {
    @extend %h3-title;
    color: $secondary-content;
    margin-bottom: rem(60);
    @include _1200 {
      margin-bottom: rem(50);
    }
    @include _768 {
      margin-bottom: rem(30);
    }
  }

  &__description {
    display: inline-block;
    max-width: rem(550);

    @extend %h3-title;
    color: $content;
    margin-bottom: rem(30);
    @include _768 {
      margin-bottom: rem(20);
    }
  }

  &__buttons {
    &-link {
      display: inline-flex;

      margin: 0 rem(10);

      @include _600 {
        margin: 0 0 rem(10);

        display: flex !important;
        justify-content: center;
        align-items: center;
      }

      & img {
        margin-right: rem(10);
      }
    }
  }
}
</style>