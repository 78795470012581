<template>
  <div class="provider" id="provider">
    <div class="container">
      <div class="provider__info">
        <h2 class="provider__info-title">Become a VPN provider</h2>
        <h3 class="provider__info-subtitle">
          Want to make money from your Internet? Here’s how.
        </h3>
        <p class="provider__info-description">
          When you connect your VPN server to the marketplace, users pay you
          directly. Setup is hassle-free.
        </p>
        <div class="provider__info-links">
          <div class="provider__info-links-item">
            <span class="provider__info-links-text">I need a server</span>
            <a
              href="https://pktpal.com"
              target="_blank"
              class="provider__info-links-link"
              >Try PKT Pal</a
            >
          </div>
          <div class="provider__info-links-item">
            <span class="provider__info-links-text">I own a server</span>
            <a
              href="https://pkt.cash"
              target="_blank"
              class="provider__info-links-link"
              >Start earning</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Provider",
};
</script>

<style lang='scss'>
.provider {
  background: url(../../assets/img/map.svg),
    url(../../assets/img/invite-wave.png);
  background-size: 50% 45%, 100% 100%;
  background-repeat: no-repeat;
  background-position: right, center;

  @include _1200 {
    background: linear-gradient(
        90deg,
        #ffffff 2.15%,
        rgba(255, 255, 255, 0) 82.47%
      ),
      url(../../assets/img/map.svg), url(../../assets/img/invite-wave.png);
    background-size: 60% 60%, 60% 60%, 100% 100%;
    background-repeat: no-repeat;
    background-position: right, right, center;
  }

  @include _600 {
    background: url(../../assets/img/invite-wave.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  padding: rem(130) 0 rem(175);
  @include _768 {
    padding: rem(34) 0 rem(80);
  }

  & > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__info {
    max-width: rem(705);

    &-title {
      @extend %h2-title;
      color: $content;
      margin-bottom: rem(60);
      @include _1200 {
        margin-bottom: rem(30);
      }
      @include _768 {
        margin-bottom: rem(20);
      }
    }

    &-subtitle,
    &-description,
    &-link {
      max-width: rem(560);
    }

    &-subtitle {
      @extend %h3-title;
      color: $content;
      margin-bottom: rem(10);
    }

    &-description {
      @extend %body;
      color: $secondary-content;
      margin-bottom: rem(60);
      @include _1200 {
        margin-bottom: rem(40);
      }
      @include _768 {
        margin-bottom: rem(31);
      }
    }

    &-links {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      white-space: nowrap;

      &-item {
        width: 50%;
      }

      &-text {
        @extend %body-xl;
        color: $content;
        margin-bottom: rem(29);
        @include _768 {
          margin-bottom: rem(31);
        }
        display: block;
      }

      &-link {
        padding: rem(10) rem(30);
        @include _768 {
          padding: rem(10) rem(18);
        }

        background-color: $secondary-bg;
        color: $content;
        border-radius: rem(5);
        border: rem(1) solid $content;

        @extend %button;
      }
    }
  }
}
</style>
