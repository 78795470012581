<template>
  <div class="how-works">
    <div class="container">
      <h2 class="how-works__title">How it works</h2>
      <h3 class="how-works__subtitle">
        Enjoy all the benefits of using AnodeVPN
      </h3>
      <!-- <PktIcon /> -->
      <p class="how-works__description">
        Enjoy all the benefits of using AnodeVPN
      </p>
      <div class="how-works__tabs">
        <ul class="how-works__tabs-tab">
          <li
            class="how-works__tabs-tab-item"
            v-for="tab in tabContent"
            :key="tab.id"
            @click="toggleTab(tab.id)"
          >
            <img :src="require('../../assets/icons/' + tab.img)" alt="" :class="{'image-filter': tab.id == currentTab}">
            <span class="how-works__tabs-tab-text">
              {{ tab.title }}
            </span>
          </li>
        </ul>
        <div class="how-works__tabs-content">
          <div class="how-works__tabs-overflow">
            <span class="how-works__tabs-content-title">{{
              currentContent.title
            }}</span>
            <p class="how-works__tabs-content-description">
              {{ currentContent.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PktIcon from '../../assets/icons/pkt-icon.svg';

export default {
  name: "HowWorks",
  // components: {
  //   PktIcon
  // },
  data() {
    return {
      currentTab: 1,
      tabContent: [
        {
          id: 0,
          title: "Connect for free, pay for speed",
          description:
            "Nobody should ever be denied access to privacy when there are idle VPN services available to use. With AnodeVPN everyone now has access to a free basic VPN connection. If you want freedom to access all parts of the Internet while keeping your data secure, a free basic connection may be all you ever need. If you need faster connection for high definition content and video conferences during peak hours, then you will need more bandwidth. AnodeVPN makes it easy to buy a bandwidth lease on your favorite VPN when you need it. If the speed slows down, switch to a better provider. Only pay for what you use, when you use it.",
          img: "wallet-icon.svg",
        },
        {
          id: 1,
          title: "Decentralized bandwidth marketplace",
          description:
            "AnodeVPN is your gateway into a decentralized bandwidth marketplace. In this marketplace, you get to choose your VPN provider, easily switch between user rated VPN providers, and even get paid to become a VPN provider if you want. No gimmicky discounts to lock you into long subscription contract. No need to pay for a bad quality service. Just a fair, open marketplace where you can get VPN for free, and only pay for the speed you need when you need it. You should not have pay for VPN service when you are not using it. AnodeVPN gets you direct VPN access, for the real cost, no more and no less.",
          img: "bag-icon.svg",
        },
        {
          id: 2,
          title: "Fast private payments",
          description:
            "Your VPN provider should not need your financial identity to give you VPN access. With AnodeVPN, you don't need even need a credit card. To provide global VPN access without a charge card, AnodeVPN accepts PKT. What is PKT? Unlike a centralized token that is minted on the Ethereum blockchain, PKT has no central issuer and features nearly free transaction fees. Using PKT, even the unbanked can get access to privacy and VPN. You can even earn PKT by selling your VPN access into the AnodeVPN marketplace. PKT makes AnodeVPN transactions fast private and secure.",
          img: "pkt-icon.svg",
        },
        {
          id: 3,
          title: "Pay your VPN by mining",
          description:
            "When you think of currency mining, you probably think of gigantic server farms using custom chips and burning more electricity than a small country. PKT is currency that is mined using a novel technology that uses Internet bandwidth instead of electricity. This means you can mine PKT using the extra Internet that normally goes to waste in your home or office. If you mine PKT, you can  use your earnings to pay for VPN in the AnodeVPN marketplace.",
          img: "card-icon.svg",
        },
        {
          id: 4,
          title: "VPN you can trust",
          description:
            "Every VPN provider out there promises the lowest price, the highest security, and (hopefully) assures you they will keep your records safe. Deciding who handles your Internet traffic is a big deal. You should be asking tough questions and you deserve more than unverifiable claims. At AnodeVPN our software is open source so  anybody can review the code and verify that only the VPN provider you choose will ever touch your data. With our user rating and reputation system, we help you navigate the VPN marketplace and get connected to a provider you can truly feel good about. Don’t like the service you are getting? Just switch to a new provider. It's that easy.",
          img: "trust-icon.svg",
        },
        {
          id: 5,
          title: "Become a VPN",
          description:
            "If you're a server administrator and you want to become your own VPN provider, you can do that too. With AnodeVPN you can offer your own easy to use VPN experience for your friends, office, business colleagues or community. Or you can open your VPN up to the world and begin earning immediately. Turning your Internet connection into a revenue source is easy.",
          img: "shield-icon.svg",
        },
      ],
    };
  },
  methods: {
    toggleTab(e) {
      this.currentTab = e;
      let items = document.querySelectorAll(".how-works__tabs-tab-item");
      items.forEach((item) => {
        item.classList.remove("active");
      });
      items[this.currentTab].classList.add("active");
      // [this.currentTab].classList.add("active");
      // const { id } = e.target.dataset;
      // this.currentTab = parseInt(id);
      // e.target.classList.toggle("active");
    },
  },
  computed: {
    currentContent() {
      return this.tabContent[this.currentTab];
    },
  },
  mounted() {
    document.querySelectorAll(".how-works__tabs-tab-item")[this.currentTab].classList.add("active");
  }
};
</script>

<style lang='scss'>
.how-works {
  padding: rem(95) 0 rem(100);
  @include _768 {
    padding: rem(52) 0 rem(48);
  }

  &__title {
    @extend %h2-title;
    color: $content;
    @include _768 {
      margin-bottom: rem(5);
    }
  }

  &__subtitle {
    @extend %h3-title;
    color: $secondary-content;
    margin-bottom: rem(20);
    @include _768 {
      margin-bottom: rem(18);
    }
  }

  &__description {
    @extend %body;
    color: $content;
    margin-bottom: rem(47);
    @include _768 {
      margin-bottom: rem(23);
    }
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    position: relative;

    @include _600 {
      flex-direction: column;
    }

    &-tab {
      width: 46.5%;
      @include _1200 {
        width: rem(70);
      }
      @include _600 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
      }

      &-text {
        margin-left: rem(8);
        @include _1200 {
          display: none;
        }
      }

      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        border-radius: rem(5);

        padding: rem(25) rem(20) rem(20);
        @include _600 {
          padding: rem(15);
        }
        @include _480 {
          justify-content: center;
          padding: rem(0);
          width: rem(46);
          height: rem(47);
        }
        @include _360 {
          padding: rem(0);
          width: rem(40);
          height: rem(41);
        }

        @extend %body;
        color: $content;
        box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.97),
          8px 8px 20px rgba(3, 44, 126, 0.15);
        cursor: pointer;

        &.active {
          box-shadow: inset -4px -4px 2px #ffffff,
            inset 2px 2px 8px rgba(3, 44, 126, 0.4);
          &::after {
            filter: invert(34%) sepia(100%) saturate(1011%) hue-rotate(181deg) brightness(92%) contrast(97%);
          }
        }

        &:not(:last-child) {
          margin-bottom: rem(20);
        }

        @include _600 {
          margin-bottom: rem(20);
        }

        & img {
          max-height: rem(26);
          @include _480 {
            max-width: rem(18);
          }
        }

        &:hover {
          opacity: 0.8;
        }

        &::after {
          position: absolute;
          right: rem(20);
          top: 50%;
          transform: translateY(-50%);
          content: '';
          background: url(../../assets/icons/arr-tabs.svg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          width: rem(6);
          height: rem(13);
          @include _1200 {
            display: none;
          }
        }
      }
    }

    &-overflow {
      max-height: 100%;
      @include _768 {
        max-height: rem(368);
      }
      overflow: auto;

      padding-right: rem(15);

      scrollbar-width: thin;
      scrollbar-color: $secondary-content #e7ecfa;
      &::-webkit-scrollbar {
        width: rem(5);
      }
      &::-webkit-scrollbar-button {
        border-radius: rem(5);
      }
    }

    &-content {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      width: 46.5%;
      border-radius: rem(5);
      @include _1200 {
        width: calc(100% - 90px);
      }
      @include _600 {
        width: 100%;
        position: static;
      }
      height: 100%;
      overflow: auto;

      padding: rem(40);
      @include _1200 {
        padding: rem(30);
      }
      @include _768 {
        padding: rem(20);
      }

      box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.97),
        8px 8px 20px rgba(3, 44, 126, 0.15);

      &-title {
        @extend %subtitle;
        color: $content;
        margin-bottom: rem(10);
        display: block;
      }

      &-description {
        @extend %body;
        color: $secondary-content;
      }
    }
  }
}
.image-filter {
  filter: invert(34%) sepia(100%) saturate(1011%) hue-rotate(181deg) brightness(92%) contrast(97%);
}
.arr-filter::after {
  
}
</style>