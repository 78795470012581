<template>
  <div>
    <Header />
    <section>
      <div class="privacy">
        <div class="container">
          <h1 class="privacy__header">Privacy Policy</h1>

          <p class="privacy__description">
            This Privacy Policy explains how your information is gathered, used, and shared by Anode Inc (“we” or “us”).  This Privacy Policy applies to the information we gather when you use our mobile applications and online products and services (“Services”) or when you interact with us.
          </p>
          <p class="privacy__description">
            We might change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy, and may at times provide additional notices such as a statement on our website or blog, or an in-app alert. We encourage you to review the Privacy Policy whenever you access the Services to stay informed about our information practices and the ways you can help protect your privacy.
          </p>
          <h2 class="privacy__title">
            1. Information We Collect and How We Use It
          </h2>
          <h3 class="privacy__subtitle">
            1.1 Information You Provide to Us
          </h3>
          <p class="privacy__description">
            We collect information you provide directly to us. For example, we collect information when you create an account, participate in any interactive features of the Services, fill out a form, complete a cryptocurrency transaction via the Services, request customer support or otherwise communicate with us. The types of information we may collect include your email address and any other information you choose to provide.
          </p>
          <h3 class="privacy__subtitle">
            1.2 Information We Collect Automatically When You Use Our Services
          </h3>
          <p class="privacy__description">
            When you access or use our Services, we automatically collect information about your device, including:
          </p>
          <ul>
            <li class="privacy__list-item">
              <strong>Device Information</strong>: We may collect information about the mobile device you use to access our mobile application, including the hardware model, operating system and version and mobile network information.
            </li>
            <li class="privacy__list-item">
              <strong>Wallet Address Information</strong>: When you create a wallet through our Services, encrypted wallet public key and private key pairs are automatically generated by the Application and stored via our Services in encrypted form. We do not store the passwords required to decrypt public or private keys.
            </li>
            <li class="privacy__list-item">
              <strong>Debugging Information</strong>: We may collect information about the use of screens and functions within the app, including timestamps, memory dumps, function calls for the purpose of identifying and removing potential bugs.
            </li>
          </ul>
          <p class="privacy__description">
            Your wallet creates and stores cryptographic private keys and passwords on your phone. We <strong>do not</strong> collect or share this information.
          </p>
          <h3 class="privacy__subtitle">
            1.3 Sharing of Information
          </h3>
          <p class="privacy__description">
            We may share information about you as follows or as otherwise described in this Privacy Policy:
          </p>
          <ul>
            <li class="privacy__list-item">
              With the cryptocurrency networks, we will share your public wallet addresses to determine your balances and transactions;
            </li>
            <li class="privacy__list-item">
              In response to a request for information if we believe disclosure is in accordance with, or as otherwise required by, any applicable law, regulation or legal process;
            </li>
            <li class="privacy__list-item">
              If we believe your actions are inconsistent with the spirit or language of our user agreements or policies, or to protect the rights, property and safety of us or others;
            </li>
            <li class="privacy__list-item">
              In connection with, or during negotiations of, any merger, sale of our assets, financing or acquisition of all or a portion of our business to another company;
            </li>
            <li class="privacy__list-item">
              With your consent or at your direction, including if we notify you through our Services that the information you provide will be shared in a particular manner and you provide such information.
            </li>
            <li class="privacy__list-item">
              We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.
            </li>
          </ul>
          <h2 class="privacy__title">
            2. Security
          </h2>
          <p class="privacy__description">
            We take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.
          </p>
          <h2 class="privacy__title">
            3. Your Choices
          </h2>
          <h3 class="privacy__subtitle">
            3.1 Account Information
          </h3>
          <p class="privacy__description">
            You may update or correct information about you or your account at any time by logging into your online account, if applicable.
          </p>
          <h3 class="privacy__subtitle">
            3.2 Push Notifications
          </h3>
          <p class="privacy__description">
            With your consent, we may send standard push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.
          </p>
          <h3 class="privacy__subtitle">
            3.3 Promotional Communications
          </h3>
          <p class="privacy__description">
            You may opt out of receiving promotional messages from us by following the instructions in those messages. If you opt out, we may still send you transactional or relationship messages, such as those about your account or our ongoing business relations.
          </p>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: 'PrivacyPolicy',
  components: {
    Header,
    Footer
  },
  methods: {
  },
};
</script>

<style lang='scss'>
.privacy {
  padding: rem(95) 0 rem(100);
  @include _768 {
    padding: rem(52) 0 rem(48);
  }

  &__header {
    @extend %h2-title;
    color: $content;
    @include _768 {
      margin-bottom: rem(5);
    }
  }

  &__title {
    @extend %h3-title;
    color: $content;
    @include _768 {
      margin-bottom: rem(5);
    }
  }

  &__subtitle {
    @extend %h3-title;
    color: $secondary-content;
    margin-bottom: rem(20);
    @include _768 {
      margin-bottom: rem(18);
    }
  }

  &__description {
    @extend %body;
    color: $content;
    margin-bottom: rem(47);
    @include _768 {
      margin-bottom: rem(23);
    }
  }

  &__list-item {
    @extend %body;
    color: $content;
    margin-bottom: rem(47);
    @include _768 {
      margin-bottom: rem(23);
    }
  }


}
</style>
