import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import App from "./App";
import AnodeWalletHelp from "./pages/AnodeWalletHelp.vue";
import Home from "./pages/Home.vue";
import PrivacyPolicy from "./pages/PrivacyPolicy.vue";

// import VueTypedJs from 'vue-typed-js'
import "./registerServiceWorker";

import store from "./store";

const routes = [
  { path: "/", component: Home },
  { path: "/privacy", component: PrivacyPolicy },
  { path: "/wallet-help", component: AnodeWalletHelp },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.use(store);

app.mount("#app");

// createApp(App).use(store).mount('#app')
