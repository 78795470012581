<template>
  <header class="header">
    <figure class="header__logo">
      <img src="../assets/img/header-logo.svg" alt="" />
    </figure>
    <ul class="header__nav">
      <li class="header__nav-item">
        <a href="#section-card" class="header__nav-link link-scroll">What's AnodeVPN</a>
      </li>
      <li class="header__nav-item">
        <a href="#features" class="header__nav-link link-scroll">Features</a>
      </li>
      <li class="header__nav-item">
        <a href="#provider" class="header__nav-link link-scroll">VPN Providers</a>
      </li>
    </ul>
    <div class="header__select">
      <SelectLanguage />
    </div>
    <a href="#everyone" class="header__premium btn link-scroll">Go Premium</a>
    <div class="header__burger" @click="burgerClick">
      <span></span>
    </div>
  </header>
</template>

<script>
import SelectLanguage from "./SelectLanguage";

export default {
  name: "Header",
  methods: {
    burgerClick() {
      document.querySelector(".header__burger").classList.toggle("active");
      document.querySelector(".header__nav").classList.toggle("active");
    }
  },
  components: {
    SelectLanguage,
  },
};
</script>

<style lang='scss'>
.header {
  position: sticky;
  top: 0;
  z-index: 111;

  width: 100%;
  height: rem(100);
  padding: 0 rem(63) 0 rem(60);
  @include _1200 {
    height: rem(70);
    padding: 0 rem(30);
  }
  @include _768 {
    height: rem(56);
    padding: 0 rem(20);
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $white;

  &__logo {
    @include _768 {
      max-width: rem(80);
    }
  }

  &__select {
    margin-left: rem(50);
    @include _1200 {
      margin-left: auto;
    }
    @include _768 {
      display: none;
    }
  }

  &__nav {
    display: flex;
    @include _1200 {
      position: fixed;
      top: rem(70);
      left: 0;
      height: calc(100% - 70px);
      width: 100%;
      padding-top: rem(20);

      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      background-color: $white;
      transition: 0.3s all ease;
      transform: scaleY(0);
      transform-origin: top;

      &.active {
        transform: scaleY(1);
      }
    }
    @include _768 {
      top: rem(56);
      height: calc(100% - 56px);
    }

    margin-left: auto;

    &-item {
      margin-left: rem(30);
      @include _1200 {
        margin-left: 0;
        margin-top: rem(50);
      }
      @include _768 {
        margin-left: 0;
        margin-top: rem(30);
      }
    }

    &-link {
      @extend %body;
      color: $content;
    }
  }

  &__premium {
    margin-left: rem(52);
    @include _768 {
      margin-left: auto;
    }
  }

  &__burger {
    display: none;
    @include _1200 {
      display: block;
    }
    position: relative;

    width: rem(31);
    height: rem(32);
    margin-left: rem(26);

    cursor: pointer;

    & span {
      position: absolute;
      top: rem(13);
      left: 0;

      display: block;
      width: rem(29);
      height: rem(4);

      background-color: $content;
      border-radius: rem(4);
      transition: 0.3s all ease;
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 0;
      display: block;
      width: rem(29);
      height: rem(4);

      background-color: $content;
      border-radius: rem(4);
      transition: 0.3s all ease;
    }

    &::after {
      top: rem(1);
    }

    &::before {
      top: rem(25);
    }

    &.active span {
      transform: scale(0);
    }

    &.active::after {
      transform: rotate(45deg);
      top: rem(13);
    }

    &.active::before {
      transform: rotate(-45deg);
      top: rem(13);
    }
  }
}
</style>