<template>
  <div class="first-section">
    <div class="container">
      <div class="first-section__wrap">
        <div class="first-section__info">
          <h3 class="first-section__info-subtitle">
            The first VPN marketplace.
          </h3>
          <!-- <div class="first-section__info-typing">
            <h1 class="first-section__info-title">The Future of VPN</h1>
          </div> -->
          <div class="first-section__info-typing">
            <h1 id="typing" class="first-section__info-title"></h1>
            <h1 class="first-section__info-title">VPN</h1>
          </div>
          <ul class="first-section__info-list">
            <li
              class="first-section__info-list-item"
              v-for="(adv, index) in advantages"
              :key="index"
            >
              <figure class="first-section__info-list-img">
                <img :src="require(`@/assets/icons/${adv.img}`)" alt="" />
              </figure>
              <span class="first-section__info-list-text">{{ adv.title }}</span>
            </li>
          </ul>
          <h4 class="provider__info-links-text">Download now</h4>
          <a href="https://play.google.com/store/apps/details?id=co.anode.anodium.playstore" target="_blank" class="makes__info-buttons-link btn-light">Android</a>
          <a href="https://apps.apple.com/us/app/anode-wallet/id1609509719" target="_blank" class="makes__info-buttons-link btn-light">iOS</a>
        </div>
        <figure class="first-section__img">
          <img src="../../assets/img/first-section-main.svg" alt="" />
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import Typed from "typed.js";

export default {
  name: "FirstSection",
  data() {
    return {
      advantages: [
        {
          title: "Open-source",
          img: "open-sourse.svg",
        },
        {
          title: "Private",
          img: "privat.svg",
        },
        {
          title: "Decentralized",
          img: "decentralized.svg",
        },
        {
          title: "Secure",
          img: "secure.svg",
        },
      ],
      i: 0,
    };
  },
  mounted() {
    new Typed("#typing", {
      strings: [
        "The Future of",
        "The Fastest",
        "Most Secure",
        "User-rated",
        "Trustworthy",
        "Lowest Cost",
        "Anode",
      ],
      typeSpeed: 100,
      backSpeed: 50,
      loop: true,
    });
  },
};
</script>

<style lang='scss'>
.first-section {
  background: url(../../assets/img/header-wave.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;

  &__wrap {
    display: flex;

    padding-top: rem(2);
    padding-bottom: rem(140);
    @include _1200 {
      padding-top: rem(50);
      padding-bottom: rem(40);
    }
    @include _768 {
      padding-top: rem(20);
      padding-bottom: rem(100);
    }

    justify-content: space-between;
    align-items: center;
    @include _1200 {
      flex-direction: column;
    }
  }

  &__img {
    width: 58%;
    transform: translateX(150px);
    @include _1600 {
      transform: translateX(80px);
    }
    @include _1350 {
      width: 50%;
      transform: none;
    }
    @include _1200 {
      margin-top: rem(-180);
      width: 70%;
      align-self: flex-end;
      transform: none;
    }
    @include _600 {
      order: -1;
      margin-top: 0;
      width: 74%;
      align-self: center;
      margin-bottom: rem(23);
    }
  }

  &__info {
    position: relative;
    z-index: 2;

    width: 50%;
    @include _1200 {
      width: 100%;
    }
    align-self: center;

    &-title {
      display: inline-block;
      @extend %h1-title;
      color: $content;
    }

    &-typing {
      margin-bottom: rem(20);
      @include _400 {
        height: rem(84);
      }

      & .typed-cursor {
        @extend %h1-title;
        @extend %roboto;
        color: $content;
        margin-left: rem(20);
        margin-right: rem(10);
      }
    }

    &-subtitle {
      @extend %h3-title;
      color: $secondary-content;
      margin-bottom: rem(20);
      @include _600 {
        margin-bottom: rem(10);
      }
    }

    &-list {
      display: grid;
      max-width: rem(372);
      grid-gap: rem(10);
      grid-template-columns: repeat(2, minmax(121px, 1fr));
      margin-bottom: rem(70);
      @include _600 {
        margin-bottom: rem(24);
      }

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: rem(12) rem(10);

        background-color: $secondary-bg;
        border-radius: rem(5);
      }

      &-img {
        margin-right: rem(7);
        display: flex;
      }

      &-text {
        @extend %body;
        color: $content;
      }
    }

    &-button {
      padding: rem(10) rem(20);

      background-color: $content;
      color: $white;
      border-radius: rem(5);
    }
  }
}
</style>