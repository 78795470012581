<template>
  <div class="invite">
    <div class="container">
      <h2 class="invite__title">
        Invite a friend and get 1 month of Go Premium for free!
      </h2>
      <a href="#" class="btn-blue invite__link" @click.prevent="showPopup"
        >Invite a friend</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Invite",
  methods: {
    showPopup() {
      this.$store.dispatch("actStatePopup", true);
    },
  },
};
</script>

<style lang='scss'>
.invite {
  padding: rem(111) 0 rem(118);
  @include _768 {
    padding: rem(53) 0 rem(60);
  }

  background: url(../../assets/img/invite-wave.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;

  &__title {
    @extend %h2-title;
    max-width: rem(750);
    color: $content;
    margin: 0 auto rem(47);
    @include _1200 {
      margin-bottom: rem(50);
    }
    @include _768 {
      margin-bottom: rem(30);
    }
  }

  &__link {
    padding: rem(10) rem(30);
  }

  & > iframe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    z-index: 200;
  }

  &__close {
    position: fixed;
    right: rem(20);
    top: rem(20);
    z-index: 201;

    cursor: pointer;
  }
}
</style>