<template>
  <div class="makes need-some">
    <div class="container">
      <div class="makes__info">
        <figure class="makes__info-img">
          <img src="../../assets/img/meets-card-1.svg" alt="" />
        </figure>
        <h2 class="makes__info-title">Need some PKT?</h2>
        <p class="makes__info-description">
          You can convert your unused Internet bandwidth into PKT and then use
          your PKT earnings to pay for AnodeVPN.
        </p>
        <div class="makes__info-buttons">
          <a
            href="https://t.me/joinchat/FwYcdlU85n0MzGXsJq3ffg"
            target="_blank"
            class="makes__info-buttons-link btn"
            >Get PKT</a
          >
          <a
            href="https://pktpal.com/"
            target="_blank"
            class="makes__info-buttons-link btn-light"
            >Earn PKT</a
          >
        </div>
      </div>
      <figure class="makes__img">
        <img src="../../assets/img/need-some-main.svg" alt="" />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "NeedSome",
};
</script>

<style lang='scss'>
.need-some {
  padding: rem(70) 0 rem(53);
  @include _768 {
    padding: rem(70) 0 rem(0);
  }
  background: url(../../assets/img/need-some-wave.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;

  & .makes__img {
    width: 63.3%;
    transform: translateX(90px);
    @include _1200 {
      width: 100%;
      transform: none;
    }
    @include _768 {
      margin-bottom: rem(29);
    }
  }

  & .makes__info {
    width: 36.7%;
    @include _1200 {
      width: 100%;
    }
    &-img {
      @include _1200 {
        width: rem(140);
        margin-bottom: rem(23);
      }
    }
    &-buttons {
      @include _768 {
        margin-bottom: rem(0);
      }
    }
  }
}
</style>