<template>
  <div class="features" id="features">
    <div class="container">
      <h2 class="features__title">VPN Marketplace Features</h2>
      <a href="#makes" class="features__link btn-blue link-scroll">Join Waitlist</a>
      <ul class="features__list">
        <li class="features__list-item">
          <figure class="features__list-img">
            <img src="../../assets/icons/free.svg" alt="" />
          </figure>
          <span class="features__list-title">Free connect</span>
        </li>
        <li class="features__list-item">
          <figure class="features__list-img">
            <img src="../../assets/icons/rocket.svg" alt="" />
          </figure>
          <span class="features__list-title">Pay for speed</span>
        </li>
        <li class="features__list-item">
          <figure class="features__list-img">
            <img src="../../assets/icons/price.svg" alt="" />
          </figure>
          <span class="features__list-title">Price transparency</span>
        </li>
        <li class="features__list-item">
          <figure class="features__list-img">
            <img src="../../assets/icons/network.svg" alt="" />
          </figure>
          <span class="features__list-title"
            >Decentralized VPN Marketplace</span
          >
        </li>
        <li class="features__list-item">
          <figure class="features__list-img">
            <img src="../../assets/icons/pkt.svg" alt="" />
          </figure>
          <span class="features__list-title">Cash/Credit</span>
        </li>
        <li class="features__list-item">
          <figure class="features__list-img">
            <img src="../../assets/icons/union.svg" alt="" />
          </figure>
          <span class="features__list-title">Run a VPN and earn</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
};
</script>

<style lang='scss'>
.features {
  padding: rem(113) 0 rem(75);
  @include _1200 {
    padding: rem(50) 0 rem(70);
  }
  @include _768 {
    padding: rem(68) 0 rem(30);
  }

  & > .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &__title {
    @extend %h2-title;
    color: $content;
    margin-bottom: rem(50);
    @include _768 {
      margin-bottom: rem(30);
    }
  }

  &__link {
    @extend %button;
    background-color: $content;
    color: $white;
    margin-bottom: rem(56);
    @include _1200 {
      order: 5;
      margin-top: rem(50);
    }
    @include _768 {
      margin-top: rem(30);
    }
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include _1200 {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _600 {
      grid-template-columns: repeat(1, 1fr);
    }
    grid-gap: rem(20);
    text-align: center;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      padding: rem(40) rem(70) rem(33);
      @include _1200 {
        padding: rem(40) rem(30) rem(30);
      }
      @include _768 {
        padding: rem(21.5) rem(25);
      }

      border-radius: rem(5);
      box-shadow: -8px -8px 10px rgba(255, 255, 255, 0.97),
        8px 8px 20px rgba(3, 44, 126, 0.15);

      &:hover {
        opacity: 0.8
      }
    }

    &-title {
      @extend %subtitle;
      color: $content;
    }

    &-img {
      margin-bottom: rem(30);
      @include _768 {
        margin-bottom: rem(15);
      }
    }
  }
}
</style>
