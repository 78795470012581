<template>
  <div>
    <Header />
    <section>
      <FirstSection />
    </section>
    <section>
      <SectionCard />
    </section>
    <section>
      <Makes />
    </section>
    <!-- <section>
      <Rave />
    </section> -->
    <section>
      <Features />
    </section>
    <section>
      <Provider />
    </section>
    <section>
      <HowWorks />
    </section>
    <!-- <section>
      <Everyone />
    </section>
    <section>
      <FastPremium />
    </section> -->
    <section>
      <NeedSome />
    </section>
    <section>
      <Meets />
    </section>
    <section>
      <Invite />
    </section>
    <section>
      <Best />
    </section>
    <section>
      <Privacy />
    </section>
    <section>
      <LastSection />
    </section>
    <Footer />
    <Popup v-show="isShowPopup" />
  </div>
</template>

<script>
import Header from "../components/Header";
import FirstSection from "../components/section/FirstSection";
import SectionCard from "../components/section/SectionCard";
import Makes from "../components/section/Makes";
// import Rave from "../components/section/Rave";
import Features from "../components/section/Features";
import Provider from "../components/section/Provider";
import HowWorks from "../components/section/HowWorks";
// import Everyone from "../components/section/Everyone";
// import FastPremium from "../components/section/FastPremium";
import NeedSome from "../components/section/NeedSome";
import Meets from "../components/section/Meets";
import Invite from "../components/section/Invite";
import Best from "../components/section/Best";
import Privacy from "../components/section/Privacy";
import LastSection from "../components/section/LastSection";
import Footer from "../components/Footer";
import Popup from "../components/Popup";

export default {
  components: {
    Header,
    FirstSection,
    SectionCard,
    Makes,
    // Rave,
    Features,
    Provider,
    HowWorks,
    // Everyone,
    // FastPremium,
    NeedSome,
    Meets,
    Invite,
    Best,
    Privacy,
    LastSection,
    Footer,
    Popup,
  },
  methods: {
    scrollToSection() {
      const links = document.querySelectorAll(".link-scroll");
      links.forEach((i) => {
        i.addEventListener("click", (e) => {
          e.preventDefault();
          document.querySelector(".header__burger").classList.remove("active");
          document.querySelector(".header__nav").classList.remove("active");
          const to = e.target.attributes.href.value;
          let heightTop = document.querySelector(to).offsetTop;
          if (window.innerWidth >= 1200) {
            heightTop -= 125;
            window.scrollTo(0, heightTop);
          } else if (
            window.innerWidth < 768 &&
            e.target.attributes.href.nodeValue == "#makes"
          ) {
            const toMakes = document
              .querySelector("#makes__info-klavio")
              .getBoundingClientRect().top;
            heightTop = toMakes;
            heightTop -= 56;
            window.scrollBy(0, heightTop);
          } else if (window.innerWidth < 768) {
            heightTop -= 56;
            window.scrollTo(0, heightTop);
          } else {
            heightTop -= 70;
            window.scrollTo(0, heightTop);
          }
        });
      });
    },
  },
  mounted() {
    this.scrollToSection();
  },
  computed: {
    isShowPopup() {
      return this.$store.getters.stateShowPopup;
    },
  },
};
</script>

<style lang="scss">
// @import "@/assets/scss/main.scss";
</style>
