import axios from 'axios';
import { createStore } from 'vuex'

export default createStore({
  state: {
    reviews: [],
    isShowPopup: false
  },
  mutations: {
    setReviews(state, reviews) {
      state.reviews = reviews;
    },
    setPopup(state, value) {
      state.isShowPopup = value;
    }
  },
  actions: {
    fetch: async (context) => {
      const { data } = await axios.post('https://api.yotpo.com/oauth/token', {
        "client_id": "vDMdRFIoNWz3RnYjGjq2IhoeF5sKt6a5FqoIvmTa",
        "client_secret": "p3s1lb4OKcY5ZjihrEdsKr94w3qcstoBOAIxCUWs",
        "grant_type": "client_credentials"
      });
      setTimeout(async () => {
        const obj = await axios.get(`https://api.yotpo.com/v1/apps/${process.env.VUE_APP_YOTPO_APP_KEY}/reviews?utoken=${data.access_token}&count=6`);
        context.commit('setReviews', obj.data.reviews);
      }, 200)
    },
    actStatePopup({commit}, value) {
      commit('setPopup', value)
    }
  },
  getters: {
    reviews: state => state.reviews,
    stateShowPopup: state => state.isShowPopup
  },
});
